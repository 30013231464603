import React from 'react';
import { SkeletonBodyText } from '@shopify/polaris';
import './style.css';

export default function SubscriptionBoxSkeleton() {
  return (
    <div className={'left-bar-header'}>
      <SkeletonBodyText lines={2} />
    </div>
  );
}
