import { connect } from 'react-redux';
import LeftNavigation from './LeftNavigation';

function mapStateToProps(state) {
  return {
    storeUUID: state.shop.storeUUID,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LeftNavigation);
